import axios from "axios";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import FileUploader from "../components/FileUploader";
import toast from "react-hot-toast";
import JsBarcode from "jsbarcode";
import html2canvas from "html2canvas";
import { useEffect, useRef } from "react";
import "../styles/People.css";
import "../styles/IDCard.css";
import { nationalities } from "../utils/nationalities";
import config from "../config";
import Modal from "../components/Modal";
import DocumentUploader from "../components/DocumentUploader";

export default function People() {
  const { t, i18n } = useTranslation();
  const [fieldSets, setFieldSets] = useState([]);
  const [barcodeValue, setBarcodeValue] = useState("");
  const identityCardRef = useRef(null);

  const [images, setImages] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [docs, setDocs] = useState();
  const [oldImages, setOldImages] = useState();
  const videoRef = useRef(null); // Reference to the video element
  const canvasRef = useRef(null); // Reference to the canvas element

  const initialFormState = {
    firstName: "",
    lastName: "",
    gender: "",
    meals: null, // explicitly set as null
    isee: "",
    child: "",
    nationality: "",
    bornOn: "",
    maritalStatus: "",
    livewith: "",
    emailAddress: "",
    country: "",
    streetAddress: "",
    city: "",
    zip: "",
    cell: "",
    currentJob: "",
    document: [],
    documentType: "",
  };

  const [formData, setFormData] = useState(initialFormState);

  // const inputHandler = (e) => {
  //   const { name, type, files, value } = e.target;

  //   if (type === "file" && files) {
  //     const fileArray = Array.from(files); // Convert FileList to an array
  //     // setFormData((prevData) => ({
  //     //   ...prevData,
  //     //   [name]: fileArray, // Store the array of files
  //     // }));

  //     setFormData((prevData) => ({
  //       ...prevData,
  //       document: [...prevData.document, ...fileArray], // Append new files to existing files
  //     }));
  //   } else {
  //     setFormData((prevData) => ({
  //       ...prevData,
  //       [name]: value, // For other input fields, just store the value
  //     }));
  //   }

  //   // setFormData((prevData) => ({
  //   //   ...prevData,
  //   //   [e.target.name]: e.target.value,
  //   // }));
  // };

  // Funzione per ottenere il nome del paese in base alla lingua corrente

  const fileInputRef = useRef(null); // Create a ref

  const inputHandler = (e) => {
    const { name, type, files, value } = e.target;

    if (type === "file" && files) {
      const fileArray = Array.from(files);
      setFormData((prevData) => ({
        ...prevData,
        document: [...(prevData.document || []), ...fileArray],
      }));
    } else if (name === "meals") {
      // Simplified meals handling
      const parsedValue =
        value === "" ? null : isNaN(parseInt(value)) ? null : parseInt(value);

      setFormData((prevData) => ({
        ...prevData,
        [name]: parsedValue,
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const removeDocument = (index) => {
    setFormData((prevData) => ({
      ...prevData,
      document: prevData.document.filter((_, i) => i !== index),
    }));
  };

  const onSubmitHandler = async (e) => {
    e.preventDefault();
    try {
      const formState = new FormData();

      // Handle document files with type prefix
      if (Array.isArray(formData.document)) {
        formData.document.forEach((file, index) => {
          if (file instanceof File) {
            // Create new filename with document type prefix
            const documentType = formData.documentType || 'unknown';
            const newFileName = `${documentType}_${file.name}`;

            // Create new File object with modified name
            const modifiedFile = new File([file], newFileName, {
              type: file.type
            });

            formState.append("document", modifiedFile);
            console.log("Document being sent:", newFileName);
          }
        });
      }

      // Handle other form fields
      Object.entries(formData).forEach(([key, value]) => {
        // Skip document and documentType as we've handled them above
        if (key === 'document' || key === 'documentType') {
          return;
        }

        // Handle meals
        if (key === 'meals') {
          if (value === null) {
            formState.append('meals', '');
          } else {
            formState.append('meals', value.toString());
          }
          return;
        }

        // Handle other arrays
        if (Array.isArray(value)) {
          formState.append(key, JSON.stringify(value));
        }
        // Handle all other fields
        else if (value !== null && value !== undefined && value !== '') {
          formState.append(key, value);
        }
      });

      // Append profile image if it exists
      if (images) {
        formState.append("SelectedImages", images);
      }

      // Log the form state before submission for debugging
      console.log("Form Data before submission:");
      for (let [key, value] of formState.entries()) {
        console.log(`${key}:`, value);
      }

      // Make the API call
      const response = await axios.post(
        `${config.ipAddress}/person`,
        formState,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      // Handle successful response
      const userId = response.data.id;
      setBarcodeValue(userId);

      // Reset form after successful submission
      setFormData(initialFormState);
      setImages(null);
      setDocs(null);
      if (fileInputRef.current) {
        fileInputRef.current.value = null;
      }
      setBarcodeValue("");

      toast.success("Persona aggiunta con successo");
    } catch (error) {
      console.error("Submission error details:", error.response?.data);
      toast.error(error.response?.data?.message || "Error submitting form");
      // Don't reset form data on error
    }
  };

  const getCountryName = (alpha2Code, language) => {
    const country = nationalities.find(
      (nat) => nat.alpha_2_code === alpha2Code
    );
    if (!country) return ""; // Se il paese non viene trovato, restituisce una stringa vuota.

    // Restituisci il nome della nazionalità in base alla lingua attuale.
    return language === "it" ? country.it_short_name : country.en_short_name;
  };

  const generateIdentityCard = async () => {
    const canvas = await html2canvas(identityCardRef.current);
    const imageData = canvas.toDataURL("image/png");
    const printableWindow = window.open("", "_blank");
    printableWindow.document.write(
      `<img src="${imageData}" style="width:10%" />`
    );
    printableWindow.document.close();
    printableWindow.print();
  };

  const calculateBarcodeValue = () => {
    // Here you can derive the barcode value based on user's data
    // For example, concatenate user's ID, first name, last name, etc.
    const { id, firstName, lastName, cell, streetAddress, city } = formData;
    // Pad the ID with leading zeros if it's less than 10
    const paddedId = id < 10 ? `0${id}` : id;
    return `${paddedId}-${firstName}-${lastName}-${cell}-${streetAddress}-${city}`;
  };

  const openCamera = async () => {
    setIsModalOpen(true);
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: { facingMode: "user" }, // Use 'user' for front camera
        audio: false,
      });
      videoRef.current.srcObject = stream;
    } catch (error) {
      console.error("Error accessing camera:", error);
    }
  };

  // Function to capture the image from the video stream
  const captureImage = () => {
    const canvas = canvasRef.current;
    const video = videoRef.current;
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;

    const context = canvas.getContext("2d");
    context.drawImage(video, 0, 0, canvas.width, canvas.height);

    canvas.toBlob((blob) => {
      const file = new File([blob], "captured-image.png", {
        type: "image/png",
      });
      // setImageFile(file); // Set the image as a File object
      console.log(file);
      setImages(file);
      setIsModalOpen(false);
      // Stop the video stream after capturing the image
      const stream = video.srcObject;
      const tracks = stream.getTracks();
      tracks.forEach((track) => track.stop());
    }, "image/png");

    // Get the image data from the canvas
    // const imageData = canvas.toDataURL('image/png');
    // setImage(imageData);

    // Stop the video stream after capturing the image
    const stream = video.srcObject;
    const tracks = stream.getTracks();
    tracks.forEach((track) => track.stop());
  };

  const setModalOpen = () => {
    setIsModalOpen(false);
  };
  const setDocumentModalOpen = () => {
    setDocumentModalOpen(false);
  };

  // Calculate new barcode value whenever formData changes
  useEffect(() => {
    const newBarcodeValue = calculateBarcodeValue();
    setBarcodeValue(newBarcodeValue);
  }, [formData]);

  useEffect(() => {
    // Initialize JsBarcode after component is mounted
    JsBarcode("#barcode", "barcode value", {
      format: "CODE128",
      width: 1,
      height: 30,
      displayValue: false,
    });
    const imageElement = identityCardRef.current.querySelector("#userImage");
    if (imageElement && images && images.length > 0) {
      const imageUrl = URL.createObjectURL(images[0]);
      imageElement.src = imageUrl;
    }
  }, [barcodeValue, images]); // Include barcodeValue and images in the dependency array

  const [isDocumentModalOpen, setIsDocumentModalOpen] = useState(false);
  const documentVideoRef = useRef(null);
  const documentCanvasRef = useRef(null);

  // // Function to handle camera capture (you’ll need to implement capturing logic here)
  const [files, setFiles] = useState([]); // State to hold multiple files

  const openDocumentCamera = async () => {
    setIsDocumentModalOpen(true);
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: { facingMode: "user" }, // Use 'user' for front camera
        audio: false,
      });
      videoRef.current.srcObject = stream;
    } catch (error) {
      console.error("Error accessing camera:", error);
    }
  };

  // Function to capture the image from the video stream
  const captureDocument = () => {
    const canvas = canvasRef.current;
    const video = videoRef.current;
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;

    const context = canvas.getContext("2d");
    context.drawImage(video, 0, 0, canvas.width, canvas.height);

    canvas.toBlob((blob) => {
      const file = new File([blob], "captured-image.png", {
        type: "image/png",
      });
      setFormData((prev) => ({
        ...prev,
        document: [...prev.document, file],
      }));
      setIsDocumentModalOpen(false);
      // setImageFile(file); // Set the image as a File object
      //console.log(file);
      //setImages(file);
      setIsDocumentModalOpen(false);
      // Stop the video stream after capturing the image
      const stream = video.srcObject;
      const tracks = stream.getTracks();
      tracks.forEach((track) => track.stop());
    }, "image/png");

    // Get the image data from the canvas
    // const imageData = canvas.toDataURL('image/png');
    // setImage(imageData);

    // Stop the video stream after capturing the image
    const stream = video.srcObject;
    const tracks = stream.getTracks();
    tracks.forEach((track) => track.stop());
  };

  return (
    <section className=" bg-zinc-50  overflow-scroll h-screen w-[100%] ">
      <form className=" lg:col-span-9" method="post" onSubmit={onSubmitHandler}>
        {/* Profile section */}
        <div className="px-4 py-6 sm:p-6 lg:pb-8 border border-bottom">
          <h2 className="text-base font-semibold leading-7 text-gray-900">
            {t("User Detail")}{" "}
          </h2>
          <p className="mt-1 text-sm leading-6 text-gray-600">
            {t("Put All User Data")}
          </p>
          <div className="mt-6 flex flex-col lg:flex-row">
            <div className="flex-grow space-y-6">
              <div className="grid  gap-4 sm:grid-cols-2 md:grid-cols-3">
                <div className="">
                  <label
                    htmlFor="first-name"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    {t("First Name")}
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      name="firstName"
                      id="firstName"
                      required
                      onChange={inputHandler}
                      value={formData.firstName || ""}
                      autoComplete="given-name"
                      className="px-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>
                <div className="">
                  <label
                    htmlFor="lastName"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    {t("Last Name")}
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      name="lastName"
                      id="lastName"
                      required
                      onChange={inputHandler}
                      autoComplete="family-name"
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6"
                      value={formData.lastName || ""}
                    />
                  </div>
                </div>

                <div className="">
                  <label
                    htmlFor="maritalStatus"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    {t("Marital status")}
                  </label>
                  <div className="mt-2">
                    <select
                      id="maritalStatus"
                      name="maritalStatus"
                      value={formData.maritalStatus || ""}
                      onChange={inputHandler}
                      autoComplete="Marital status"
                      className="block w-[100%] rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset"
                    >
                      <option value={"Celibe"}>{t("Celibate")}</option>
                      <option value={"Nubile"}>{t("Nubile")}</option>
                      <option value={"Married"}>{t("Married")}</option>
                      <option value={"Widowed"}>{t("Widower")}</option>
                      <option value={"Divorced"}>{t("Divorced")}</option>
                    </select>
                  </div>
                </div>

                <div className="">
                  <label
                    htmlFor="gender"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    {t("Gender")}
                  </label>
                  <div className="mt-2">
                    <select
                      id="gender"
                      name="gender"
                      value={formData.gender || ""}
                      onChange={inputHandler}
                      autoComplete="Gender"
                      className="block w-[100%] rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset"
                    >
                      <option value={"Male"}>{t("Male")}</option>
                      <option value={"Female"}>{t("Female")}</option>
                      <option value={"Other"}>{t("Other")}</option>
                    </select>
                  </div>
                </div>

                <div className="">
                  <label
                    htmlFor="nationality"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    {t("Nationality")}
                  </label>
                  <div className="mt-2">
                    <select
                      type="text"
                      name="nationality"
                      id="nationality"
                      value={formData.nationality || ""}
                      onChange={inputHandler}
                      autoComplete="family-name"
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6"
                    >
                      {nationalities.map((nationality) => (
                        <option
                          key={nationality.alpha_2_code}
                          value={nationality.alpha_2_code}
                        >
                          {getCountryName(
                            nationality.alpha_2_code,
                            i18n.language
                          )}{" "}
                          {/* Modifica qui */}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className="">
                  <label
                    htmlFor="bornOn"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    {t("Born on")}
                  </label>
                  <div className="mt-2">
                    <input
                      type="date"
                      name="bornOn"
                      id="bornOn"
                      value={formData.bornOn || ""}
                      onChange={inputHandler}
                      autoComplete="family-name"
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>

                <div className="">
                  <label
                    htmlFor="meals"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    {t("meals (only for family)")}
                  </label>
                  <div className="mt-2">
                    {/* <input
                      type="number"
                      name="meals"
                      id="meals"
                      min="0"
                      max="100"
                      // value={formData.meals || null}
                      // onChange={(e) => {
                      //   inputHandler(e);
                      //   setFieldSets([
                      //     ...Array.from(
                      //       { length: +e.target.value },
                      //       (_, index) => ({ id: index + 1 })
                      //     ),
                      //   ]);
                      // }}
                      value={formData.meals !== null ? formData.meals : ""}
                      onChange={inputHandler}
                      autoComplete="Numero di figli"
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6"
                    /> */}

                    <input
                      type="number"
                      name="meals"
                      id="meals"
                      min="0"
                      max="100"
                      value={formData.meals || ""}
                      onChange={inputHandler}
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>
                <div className="">
                  <label
                    htmlFor="child"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    {t("How many Children")}
                  </label>
                  <div className="mt-2">
                    <input
                      id="child"
                      name="child"
                      type="number"
                      onChange={inputHandler}
                      value={formData.child || ""}
                      autoComplete="email"
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>
                <div className="">
                  <label
                    htmlFor="livewith"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    {t("Live with")}
                  </label>
                  <div className="mt-2">
                    <select
                      type="text"
                      name="livewith"
                      id="livewith"
                      value={formData.livewith || ""}
                      onChange={inputHandler}
                      autoComplete="family-name"
                      className="block w-[100%] rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset"
                    >
                      <option value={"With Me"}>{t("With Me")}</option>
                      <option value={"Father"}>{t("Father")}</option>
                      <option value={"Mother"}>{t("Mother")}</option>
                      <option value={"On their own"}>{t("On their own")}</option>
                    </select>
                  </div>
                </div>

                <div className="sm:col-span-2 md:col-span-1 mt-2 gap-x-6 gap-y-8">
                  <label
                    htmlFor="Cell"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    {t("Mobile phone")}
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      name="cell"
                      id="cell"
                      value={formData.cell || ""}
                      onChange={inputHandler}
                      autoComplete="address-level2"
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>

                <div className="sm:col-span-2 md:col-span-1 mt-2 gap-x-6 gap-y-8">
                  <label
                    htmlFor="dateOfArrival"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    {t("Date of Arrival")}
                  </label>
                  <div className="mt-2">
                    <input
                      type="date"
                      name="dateOfArrival"
                      id="dateOfArrival"
                      value={formData?.dateOfArrival || ""}
                      onChange={inputHandler}
                      autoComplete="dateOfArrival"
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>
                <div className="sm:col-span-2 md:col-span-1 mt-2 gap-x-6 gap-y-8">
                  <label
                    htmlFor="allergies"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    {t("Allergies")}
                  </label>
                  <div className="mt-2">
                    <select
                      id="allergies"
                      name="allergies"
                      value={formData.allergies || ""}
                      onChange={inputHandler}
                      autoComplete="allergies"
                      className="block w-[100%] rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset"
                      defaultValue={"None"}
                    >
                      <option value={"None"}>{t("None")}</option>
                      <option value={"Vegan"}>{t("Vegan")}</option>
                      <option value={"Vegetarian"}>{t("Vegetarian")}</option>
                      <option value={"Kosher"}>{t("Kosher")}</option>
                      <option value={"Gluten Free"}>{t("Gluten Free")}</option>
                      <option value={"Halal"}>{t("Halal")}</option>
                    </select>
                  </div>
                </div>

                <div className="sm:col-span-2 md:col-span-1 mt-2 gap-x-6 gap-y-8">
                  <label
                    htmlFor="document-image"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    {t("Document Image")}
                  </label>

                  <div className="mt-2">
                    <input
                      type="file"
                      id="document-image"
                      name="document-image"
                      multiple // This enables multiple file selection
                      ref={fileInputRef} // Attach ref to the file input
                      onChange={inputHandler} // This handler will need to be adapted to handle multiple files
                      className="block w-[100%] rounded-md border-0 p-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset"
                    />
                  </div> <div className="mt-2">
                    {/* Check if formData.document is not null and is an array */}
                    {Array.isArray(formData.document) &&
                      formData.document.length > 0 ? (
                      <ul className="list-disc list-inside">
                        {formData.document.map((file, index) => (
                          <li key={index} className="text-sm text-gray-600 flex justify-between items-center">
                            {file.name}
                            <button
                              onClick={() => removeDocument(index)}
                              className="bg-red-500 text-white rounded-full p-1 ml-2 hover:bg-red-600"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-4 w-4"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth={2}
                                  d="M6 18L18 6M6 6l12 12"
                                />
                              </svg>
                            </button>
                          </li>
                        ))}
                      </ul>
                    ) : (
                      <p className="text-sm text-gray-500">
                        {t("No document selected")}
                      </p>
                    )}
                  </div>

                  <DocumentUploader
                    files={files}
                    setFiles={setFiles}
                    openDocumentCamera={openDocumentCamera}
                  />

                  {/* <div className="mt-2">
                    {formData.document.length > 0 && (
                      <ul className="list-disc list-inside">
                        {formData.document.map((file, index) => (
                          <li key={index} className="text-sm text-gray-600">
                            {file.name}
                          </li>
                        ))}
                      </ul>
                    )}
                  </div> */}


                </div>

                <div className="sm:col-span-2 md:col-span-1 mt-2 gap-x-6 gap-y-8">
                  <label
                    htmlFor="documentType"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    {t("Document Type")}
                  </label>
                  <div className="mt-2">
                    <select
                      id="documentType"
                      name="documentType"
                      value={formData.documentType || ""}
                      onChange={inputHandler}
                      autoComplete="document"
                      className="block w-[100%] rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset"
                    >
                      <option value={"Passport"}>{t("Passport")}</option>
                      <option value={"Identity Card"}>
                        {t("Identity Card")}
                      </option>
                      <option value={"Residence Permit"}>{t("Residence Permit")}</option>
                      <option value={"Driving license"}>
                        {t("Driving license")}
                      </option>
                      <option value={"International Protection"}>
                        {t("International Protection")}
                      </option>
                      <option value={"complaint"}>{t("complaint")}</option>
                    </select>
                  </div>
                </div>

                <div className="sm:col-span-2 md:col-span-1 mt-2 gap-x-6 gap-y-8">
                  <label
                    htmlFor="documentNumber"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    {t("Document Number")}
                  </label>
                  <div className="mt-2">
                    <input
                      id="documentNumber"
                      name="documentNumber"
                      type="text"
                      onChange={inputHandler}
                      value={formData.documentNumber || ""}
                      autoComplete="Numero Documento"
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>
                <div className="sm:col-span-2 md:col-span-1 mt-2 gap-x-6 gap-y-8">
                  <label
                    htmlFor="exitDate"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    {t("Expire Date")}
                  </label>
                  <div className="mt-2">
                    <input
                      type="date"
                      name="exitDate"
                      id="exitDate"
                      value={formData.exitDate || ""}
                      onChange={inputHandler}
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-6 flex-grow lg:ml-6 lg:mt-0 lg:flex-shrink-0 lg:flex-grow-0">
              <p
                className="text-sm font-medium leading-6 text-gray-900"
                aria-hidden="true"
              >
                {t("Photo")}
              </p>

              <div className="relative overflow-hidden rounded-full lg:block">
                <div className="col-span-full ">
                  {images ? (
                    <div className="relative flex justify-center rounded-full border border-dashed border-gray-900/25 text-center">
                      <img
                        alt="selected images"
                        className="w-72 h-72"
                        src={URL?.createObjectURL(images)}
                      />
                      <div className="absolute inset-0 flex  justify-center items-center opacity-0 transition-opacity duration-300 hover:opacity-100">
                        <button
                          onClick={() => setImages(null)}
                          className="bg-red-500 text-white rounded-full p-2 hover:bg-red-600"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M6 18L18 6M6 6l12 12"
                            />
                          </svg>
                        </button>
                      </div>
                    </div>
                  ) : (
                    <FileUploader
                      file={images}
                      setFile={setImages}
                      openCamera={openCamera}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-12 border-b px-4 sm:px-6 pb-12 ">
          <h2 className="text-base font-semibold leading-7 text-gray-900">
            {t("Address Information")}
          </h2>
          <p className="mt-1 text-sm leading-6 text-gray-600">
            {t("Domicile information")}
          </p>

          <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
            <div className="sm:col-span-2 mt-2 gap-x-6 gap-y-8 ">
              <label
                htmlFor="whereyouSleep"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                {t("Where you Sleep")}
              </label>
              <div className="mt-2">
                <select
                  type="text"
                  name="whereyouSleep"
                  id="whereyouSleep"
                  value={formData.whereyouSleep || ""}
                  onChange={inputHandler}
                  autoComplete="address-level2"
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6"
                >
                  <option value={"Own Home"}>{t("Own Home")}</option>
                  <option value={"Homeless"}>{t("SFD")}</option>
                  <option value={"Friends/Family"}>
                    {t("Friends/Family")}
                  </option>
                  <option value={"Friends/Family"}>{t("Friends/Family")}</option>
                  <option value={"Caritas"}>{t("Caritas")}</option>
                  <option value={"Rent"}>{t("Rent")}</option>
                  <option value={"Inn"}>{t("Inn")}</option>
                </select>
              </div>
            </div>
            <div className="sm:col-span-2 sm:col-start-1 mt-2 gap-x-6 gap-y-8">
              <label
                htmlFor="city"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                {t("City")}
              </label>
              <div className="mt-2">
                <input
                  type="text"
                  name="city"
                  id="city"
                  value={formData.city || ""}
                  onChange={inputHandler}
                  autoComplete="address-level2"
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div className="sm:col-span-2 mt-2 gap-x-6 gap-y-8">
              <label
                htmlFor="region"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                {t("Province")}
              </label>
              <div className="mt-2">
                <input
                  type="text"
                  name="region"
                  id="region"
                  onChange={inputHandler}
                  autoComplete="address-level1"
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div className="sm:col-span-2 mt-2 gap-x-6 gap-y-8">
              <label
                htmlFor="zip"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                {t("Postal Code")}
              </label>
              <div className="mt-2">
                <input
                  type="text"
                  name="zip"
                  id="zip"
                  onChange={inputHandler}
                  value={formData.zip || ""}
                  autoComplete="postal-code"
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div className="sm:col-span-2 mt-2 gap-x-6 gap-y-8 ">
              <label
                htmlFor="streetAddress"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                {t("Street")}
              </label>
              <div className="mt-2">
                <input
                  type="text"
                  name="streetAddress"
                  id="streetAddress"
                  value={formData.streetAddress || ""}
                  onChange={inputHandler}
                  autoComplete="address-level2"
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6"
                />
              </div>
            </div>
            {/* <div className="sm:col-span-2 mt-2 gap-x-6 gap-y-8 ">
              <label
                htmlFor="homeAddress"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                {t("Home Address")}
              </label>
              <div className="mt-2">
                <input
                  type="text"
                  name="homeAddress"
                  id="homeAddress"
                  value={formData.homeAddress || ''}
                  onChange={inputHandler}
                  autoComplete="address-level2"
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6"
                />
              </div>
            </div> */}
            {/* <div className="sm:col-span-2 mt-2 gap-x-6 gap-y-8 ">
              <label
                htmlFor="cityOfDomicile"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                {t("City of Domicile")}
              </label>
              <div className="mt-2">
                <input
                  type="text"
                  name="cityOfDomicile"
                  id="cityOfDomicile"
                  value={formData.cityOfDomicile || ''}
                  onChange={inputHandler}
                  autoComplete="address-level2"
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6"
                />
              </div>
            </div> */}
            <div className="sm:col-span-2 mt-2 gap-x-6 gap-y-8 ">
              <label
                htmlFor="rentPay"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                {t("How much do you pay in rent?")}
              </label>
              <div className="mt-2">
                <input
                  type="text"
                  name="rentPay"
                  id="rentPay"
                  value={formData.rentPay || ""}
                  onChange={inputHandler}
                  autoComplete="address-level2"
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6"
                />
              </div>
            </div>
            {/* <div className="sm:col-span-2 mt-2 gap-x-6 gap-y-8 ">
              <label
                htmlFor="residenceAddress"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                {t("Residence Address")}
              </label>
              <div className="mt-2">
                <input
                  type="text"
                  name="residenceAddress"
                  id="residenceAddress"
                  value={formData.residenceAddress || ''}
                  onChange={inputHandler}
                  autoComplete="address-level2"
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6"
                />
              </div>
            </div> */}
            {/* <div className="sm:col-span-2 mt-2 gap-x-6 gap-y-8 ">
              <label
                htmlFor="cityOfResidence"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                {t("City of Residence")}
              </label>
              <div className="mt-2">
                <input
                  type="text"
                  name="cityOfResidence"
                  id="cityOfResidence"
                  value={formData.cityOfResidence || ''}
                  onChange={inputHandler}
                  autoComplete="address-level2"
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6"
                />
              </div>
            </div> */}
          </div>
        </div>

        <div className="mt-12 border-b px-4 sm:px-6 pb-12 ">
          <h2 className="text-base font-semibold leading-7 text-gray-900">
            {t("Education/Work")}
          </h2>

          <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
            <div className="sm:col-span-2 sm:col-start-1 mt-2 gap-x-6 gap-y-8">
              <label
                htmlFor="qualification"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                {t("Qualification")}
              </label>
              <div className="mt-2">
                <select
                  type="text"
                  name="qualification"
                  id="qualification"
                  value={formData.qualification || ""}
                  onChange={inputHandler}
                  autoComplete="address-level2"
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6"
                >
                  <option value={"None"}>{t("None")}</option>
                  <option value={"Degree"}>{t("Degree")}</option>
                  <option value={"High School Diploma"}>{t("High School Diploma")}</option>
                  <option value={"Mid School Diploma"}>{t("Mid School Diploma")}</option>
                  <option value={"Elementary School Diploma"}>{t("Elementary School Diploma")}</option>
                </select>
              </div>
            </div>

            <div className="sm:col-span-2 mt-2 gap-x-6 gap-y-8">
              <label
                htmlFor="currentProfessionalStatus"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                {t("Current Professional Status")}
              </label>
              <div className="mt-2">
                <select
                  type="text"
                  name="currentProfessionalStatus"
                  id="currentProfessionalStatus"
                  onChange={inputHandler}
                  autoComplete="address-level1"
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6"
                >
                  <option value={"Employed"}>{t("Employed")}</option>
                  <option value={"Unemployed"}>{t("Unemployed")}</option>
                </select>
              </div>
            </div>
            <div className="sm:col-span-2 mt-2 gap-x-6 gap-y-8">
              <label
                htmlFor="currentJob"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                {t("Current Job")}
              </label>
              <div className="mt-2">
                <input
                  type="text"
                  name="currentJob"
                  id="currentJob"
                  onChange={inputHandler}
                  value={formData.currentJob || ""}
                  autoComplete="postal-code"
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6"
                />
              </div>
            </div>
            <div className="sm:col-span-2 mt-2 gap-x-6 gap-y-8">
              <label
                htmlFor="monthlyIncome"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                {t("Monthly Income")}
              </label>
              <div className="mt-2">
                <input
                  type="text"
                  name="monthlyIncome"
                  id="monthlyIncome"
                  onChange={inputHandler}
                  value={formData.monthlyIncome || ""}
                  autoComplete="postal-code"
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div className="sm:col-span-2 mt-2 gap-x-6 gap-y-8 ">
              <label
                htmlFor="work"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                {t("Work Carried out")}
              </label>
              <div className="mt-2">
                <input
                  type="text"
                  name="work"
                  id="work"
                  value={formData.work || ""}
                  onChange={inputHandler}
                  autoComplete="address-level2"
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="mt-12 border-b px-4 sm:px-6 pb-12">
          <h2 className="text-base font-semibold leading-7 text-gray-900">
            {t("About")}
          </h2>
          <div className="mt-2">
            <textarea
              id="about"
              name="about"
              onChange={inputHandler}
              rows={3}
              className="mt-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset  sm:text-sm sm:leading-6"
              defaultValue={""}
            />
          </div>
        </div>
        {false && (
          <div className="mt-12 px-4 sm:px-6">
            <h2 className="text-base font-semibold leading-7 text-gray-900">
              {t("Family Information")}
            </h2>
            <div>
              {fieldSets.map(({ id }) => (
                <div className="mt-10 grid grid-cols-4 gap-4 " key={id}>
                  <div className="">
                    <label
                      htmlFor={`firstName-${id}`}
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      {t("First Name")}
                    </label>
                    <div className="mt-2">
                      <input
                        type="text"
                        name={`firstName-${id}`}
                        id={`firstName-${id}`}
                        onChange={(e) => {
                          setFieldSets(
                            fieldSets.map((fieldSet) => {
                              return fieldSet.id === id
                                ? { ...fieldSet, firstName: e.target.value }
                                : fieldSet;
                            })
                          );
                        }}
                        autoComplete="address-level2"
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>

                  <div className="">
                    <label
                      htmlFor={`lastName-${id}`}
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      {t("Last Name")}
                    </label>
                    <div className="mt-2">
                      <input
                        type="text"
                        name={`lastName-${id}`}
                        id={`lastName-${id}`}
                        onChange={(e) => {
                          setFieldSets(
                            fieldSets.map((fieldSet) => {
                              return fieldSet.id === id
                                ? { ...fieldSet, lastName: e.target.value }
                                : fieldSet;
                            })
                          );
                        }}
                        autoComplete="address-level2"
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>
                  <div className="">
                    <label
                      htmlFor={`relation-${id}`}
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      {t("Relation")}
                    </label>
                    <div className="mt-2">
                      <input
                        type="text"
                        name={`relation-${id}`}
                        id={`relation-${id}`}
                        onChange={(e) => {
                          setFieldSets(
                            fieldSets.map((fieldSet) => {
                              return fieldSet.id === id
                                ? { ...fieldSet, relation: e.target.value }
                                : fieldSet;
                            })
                          );
                        }}
                        autoComplete="address-level2"
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>
                  <div className="">
                    <label
                      htmlFor={`age-${id}`}
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      {t("Age")}
                    </label>
                    <div className="mt-2">
                      <input
                        type="number"
                        name={`age-${id}`}
                        id={`age-${id}`}
                        onChange={(e) => {
                          setFieldSets(
                            fieldSets.map((fieldSet) => {
                              return fieldSet.id === id
                                ? { ...fieldSet, age: e.target.value }
                                : fieldSet;
                            })
                          );
                        }}
                        autoComplete="address-level2"
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}

        <div className="flex justify-center items-center mt-16" ref={identityCardRef} id="identity-card">
          <div className="card-container mx-auto  items-center flex justify-center">
            <div className="card bg-stone-50 shadow-lg rounded-lg overflow-hidden">
              <div className="card-header bg-gray-200 flex items-center p-4">
                <img
                  className="logo w-14 h-14 object-cover rounded-full mr-4"
                  src="logo.png"
                  alt="Logo"
                />
                <div>
                  <h1 className="text-black text-2xl font-bold">Opera Sant'Antonio</h1>
                  <h2 className="text-black text-sm">PER I POVERI-ODV</h2>
                  <h3 className="text-black text-xs">Via della Fiera 5, Rimini. Tel: 0541783169.</h3>
                </div>
              </div>
              <div className="card-body flex p-4">
                <div className="user-details flex-1 flex flex-col items-start">
                  <h2 className="text-sm font-semibold">{`${formData.firstName || "John"} ${formData.lastName || "Doe"}`}</h2>
                  <h1 className="text-sm font-bold mb-2">{`${formData.bornOn}` || "2001-06-16"}</h1>

                  <svg id="barcode" class=""></svg>
                </div>

                {images && (
                  // <img
                  //   className="user-image w-20 h-20 object-cover rounded-full border border-gray-800"
                  //   src={URL.createObjectURL(images)}
                  //   alt="User"
                  // />
                  <img
                    className="user-image w-20 h-20 object-cover rounded-full border border-gray-800"
                    src={images ? URL.createObjectURL(images) : "https://static-00.iconduck.com/assets.00/avatar-default-symbolic-icon-479x512-n8sg74wg.png"}
                    alt="User"
                  />
                )}
              </div>
              <div className="card-footer bg-gray-200 text-sm text-center whitespace-nowrap pb-1">
                {t("This card can be withdrawn at any time.")}
              </div>
            </div>
          </div>
        </div>

        <div className="mt-6 flex items-center justify-end gap-x-6 mr-10">
          <button
            type="button"
            className="text-sm font-semibold leading-6 text-gray-900"
          >
            {t("Cancel")}
          </button>
          <button
            type="submit"
            className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            {t("Save")}
          </button>
        </div>
      </form>

      <Modal isModalOpen={isModalOpen} setModalOpen={setModalOpen}>
        <div>
          <video
            ref={videoRef}
            autoPlay
            style={{ width: "100%", maxWidth: "94%" }}
          ></video>
          <canvas ref={canvasRef} style={{ display: "none" }}></canvas>
          <button
            onClick={captureImage}
            style={{ marginTop: "0.7rem" }}
            className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            {t("Capture image")}
          </button>
        </div>
      </Modal>

      <Modal
        isModalOpen={isDocumentModalOpen}
        setModalOpen={setDocumentModalOpen}
      >
        <div>
          <video
            ref={videoRef}
            autoPlay
            style={{ width: "100%", maxWidth: "94%" }}
          ></video>
          <canvas ref={canvasRef} style={{ display: "none" }}></canvas>
          <button
            onClick={captureDocument}
            style={{ marginTop: "0.7rem" }}
            className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            {t("Capture document image")}
          </button>
        </div>
      </Modal>
    </section>
  );
}
