import html2canvas from "html2canvas";
import { t } from "i18next";
import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import config from "../config";

const tabs = [
  { name: "Profile", href: "#", current: false },
  { name: "Address", href: "#", current: false },
  { name: "Children", href: "#", current: false },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function FullScreenImage({ src, onClose }) {
  return (
    <div
      className="fixed inset-0 z-50 overflow-hidden bg-black bg-opacity-90 backdrop-blur-sm"
      onClick={onClose}
    >
      <div className="fixed top-0 left-0 right-0 z-50 p-4 flex justify-between items-center">
        <button
          onClick={onClose}
          className="text-white hover:text-gray-300 transition-colors duration-200 flex items-center gap-2 group"
        >
          <svg className="w-6 h-6 transform group-hover:-translate-x-1 transition-transform duration-200" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 19l-7-7m0 0l7-7m-7 7h18" />
          </svg>
          <span className="text-sm font-medium">ESC to close</span>
        </button>

        <button
          onClick={onClose}
          className="text-white hover:text-gray-300 transition-colors duration-200"
        >
          <svg className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
      </div>

      <div
        className="h-full w-full flex items-center justify-center p-4"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="relative">
          <img
            src={src}
            alt="Full screen view"
            className="max-h-[90vh] max-w-[90vw] object-contain rounded-lg shadow-2xl transform transition-transform duration-300 hover:scale-105"
          />
          <div className="absolute inset-0 ring-1 ring-white ring-opacity-20 rounded-lg pointer-events-none"></div>
        </div>
      </div>

      <div className="fixed bottom-0 left-0 right-0 p-4 text-center text-white text-sm font-medium text-opacity-75">
        Click or press ESC to close
      </div>
    </div>
  );
}

function UserData(props) {
  const {
    firstName,
    lastName,
    cell,
    bornOn,
    streetAddress,
    city,
    image,
    maritalStatus,
    gender,
    livewith,
    emailAddress,
    religion,
    child,
    meals,
    zip,
    Family,
    Documents,
  } = props;
  console.log(props, "props");
  console.log(Documents); // This will show you the actual structure
  console.log(JSON.stringify(Documents, null, 2)); // This will show the Documents array in a more readable format

  const [fullScreenImage, setFullScreenImage] = useState(null);
  const identityCardRef = useRef(null);

  const [activeTab, setActiveTab] = useState("Profile");
  const { t } = useTranslation();

  const handleImageClick = (imageSrc) => {
    setFullScreenImage(`${config.ipAddress}/file/${imageSrc}`);
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  // Add keyboard event listener for ESC key
  React.useEffect(() => {
    const handleEsc = (e) => {
      if (e.key === 'Escape' && fullScreenImage) {
        setFullScreenImage(null);
      }
    };
    window.addEventListener('keydown', handleEsc);
    return () => window.removeEventListener('keydown', handleEsc);
  }, [fullScreenImage]);

  return (
    <React.Fragment>
      {fullScreenImage && (
        <FullScreenImage
          src={fullScreenImage}
          onClose={() => setFullScreenImage(null)}
        />
      )}
      <article
        ref={identityCardRef}
        className=" bg-white shadow-lg rounded-lg overflow-hidden m-auto w-full pb-4"
      >
        {/* Profile header */}
        <div>
          <div className="h-24 w-full bg-gradient-to-r from-blue-200 to-blue-500"></div>
          <div className="mx-auto max-w-5xl px-4 sm:px-6 lg:px-8">
            <div className="-mt-12 sm:-mt-16 sm:flex sm:items-end sm:space-x-5 justify-center">
              <div className="flex">
                {image && (
                  <div className="cursor-pointer transform hover:scale-105 transition-transform duration-200">
                    <img
                      className="h-24 w-24 rounded-full ring-4 ring-white sm:h-32 sm:w-32 object-cover hover:ring-blue-500"
                      src={`${config.ipAddress}/file/${image}`}
                      alt="User"
                      onClick={() => handleImageClick(image)}
                    />
                  </div>
                )}
              </div>
            </div>
            <div className="mt-4 text-center">
              <h1 className="text-3xl font-bold text-gray-900 hover:text-blue-500 transition-colors duration-200">
                {firstName || ""} {lastName || ""}
              </h1>
            </div>
          </div>
        </div>

        <div className="mt-6 sm:mt-2 2xl:mt-5">
          <div className="border-b border-gray-200">
            <div className="mx-auto max-w-5xl px-4 sm:px-6 lg:px-8">
              <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                {tabs.map((tab) => (
                  <a
                    key={tab.name}
                    href={tab.href}
                    onClick={() => handleTabClick(tab.name)}
                    className={classNames(
                      "whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium",
                      {
                        "border-pink-500 text-gray-900": activeTab === tab.name,
                        "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700":
                          activeTab !== tab.name,
                      }
                    )}
                    aria-current={tab.current ? "page" : undefined}
                  >
                    {t(tab.name)}
                  </a>
                ))}
              </nav>
            </div>
          </div>
        </div>

        {activeTab === "Profile" && (
          <div className="mx-auto mt-6 max-w-5xl px-4 sm:px-6 lg:px-8">
            <dl className="grid grid-cols-1 gap-x-9 gap-y-8 sm:grid-cols-2">
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">
                  {" "}
                  {t("Marital status")}
                </dt>
                <dd className="mt-1 text-sm text-gray-900">
                  {" "}
                  {maritalStatus && <p>{maritalStatus}</p>}
                </dd>
              </div>
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">
                  {" "}
                  {t("Gender")}
                </dt>
                <dd className="mt-1 text-sm text-gray-900">
                  {" "}
                  {gender && <p>{gender}</p>}
                </dd>
              </div>
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">
                  {" "}
                  {t("Born in")}
                </dt>
                <dd className="mt-1 text-sm text-gray-900">
                  {livewith && <p>{livewith}</p>}
                </dd>
              </div>
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">
                  {" "}
                  {t("Born on")}
                </dt>
                <dd className="mt-1 text-sm text-gray-900">
                  {bornOn && <p>{bornOn}</p>}
                </dd>
              </div>
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">
                  {" "}
                  {t("Mobile phone")}
                </dt>
                <dd className="mt-1 text-sm text-gray-900">
                  {cell && <p>{cell}</p>}
                </dd>
              </div>
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500"> Email</dt>
                <dd className="mt-1 text-sm text-gray-900">
                  {emailAddress && <p>{emailAddress}</p>}
                </dd>
              </div>

              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">
                  {" "}
                  {t("Religion")}
                </dt>
                <dd className="mt-1 text-sm text-gray-900">
                  {religion && <p>{religion}</p>}
                </dd>
              </div>

              <div className="sm:col-span-2">
                <dt className="text-sm font-medium text-gray-500">
                  {t("About")}
                </dt>
                <dd className="mt-1 max-w-prose space-y-5 text-sm text-gray-900" />
              </div>

              {/* Add Document Images */}
              <div className="sm:col-span-2">
                <dt className="text-sm font-medium text-gray-500 mb-4">{t("Documents")}</dt>
                <dd className="mt-1 text-sm text-gray-900">
                  <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-4">
                    {Documents && Documents.length > 0 ? (
                      Documents.map((doc, index) => (
                        <div
                          key={index}
                          className="cursor-pointer group relative"
                          onClick={() => handleImageClick(doc.documentName)}
                        >
                          <div className="aspect-w-1 aspect-h-1 w-full overflow-hidden rounded-lg bg-gray-200">
                            <img
                              className="w-full h-full object-cover transform group-hover:scale-105 transition-transform duration-200"
                              src={`${config.ipAddress}/file/${doc.documentName}`}
                              alt={`Document ${index + 1}`}
                            />
                            <div className="absolute inset-0 bg-black bg-opacity-0 group-hover:bg-opacity-30 transition-opacity duration-200 flex items-center justify-center">
                              <svg
                                className="w-8 h-8 text-white opacity-0 group-hover:opacity-100 transition-opacity duration-200"
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                              >
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                              </svg>
                            </div>
                          </div>
                        </div>
                      ))
                    ) : (
                      <p className="col-span-full text-gray-500 italic">{t("No documents available")}</p>
                    )}
                  </div>
                </dd>
              </div>
            </dl>
          </div>
        )}

        {activeTab === t("Address") && (
          <div className="mx-auto mt-6 max-w-5xl px-4 sm:px-6 lg:px-8">
            <dl className="grid grid-cols-1 gap-x-9 gap-y-8 sm:grid-cols-2">
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">
                  {" "}
                  {t("City")}
                </dt>
                <dd className="mt-1 text-sm text-gray-900">
                  {city && <p>{city}</p>}
                </dd>
              </div>

              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">
                  {" "}
                  {t("Street")}
                </dt>
                <dd className="mt-1 text-sm text-gray-900">
                  {streetAddress && <p>{streetAddress}</p>}
                </dd>
              </div>
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">
                  {" "}
                  {t("Postal Code/ Zip")}
                </dt>
                <dd className="mt-1 text-sm text-gray-900">
                  {zip && <p>{zip}</p>}
                </dd>
              </div>
            </dl>
          </div>
        )}

        {activeTab === "" && (
          <div className="mx-auto mt-6 max-w-5xl px-4 sm:px-6 lg:px-8">
            <dl className="grid grid-cols-1 gap-x-9 gap-y-8 sm:grid-cols-2 mb-4">
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">
                  {" "}
                  {t("meals")}: {meals || 0}{" "}
                </dt>
              </div>
            </dl>
            {Family &&
              Family.length &&
              Family.map((fam, index) => (
                <>
                  <dl className="grid grid-cols-1 gap-x-9 gap-y-8 sm:grid-cols-4 ">
                    <div className="sm:col-span-1">
                      <dt className="text-sm font-medium text-gray-500">
                        {" "}
                        {t("First Name")}
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900">
                        {fam.firstName && <p>{fam.firstName}</p>}
                      </dd>
                    </div>

                    <div className="sm:col-span-1">
                      <dt className="text-sm font-medium text-gray-500">
                        {" "}
                        {t("Last Name")}
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900">
                        {fam.lastName && <p>{fam.lastName}</p>}
                      </dd>
                    </div>
                    <div className="sm:col-span-1">
                      <dt className="text-sm font-medium text-gray-500">
                        {" "}
                        {t("Last Name")}
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900">
                        {fam.relation && <p>{fam.relation}</p>}
                      </dd>
                    </div>
                    <div className="sm:col-span-1">
                      <dt className="text-sm font-medium text-gray-500">
                        {" "}
                        {t("Last Name")}
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900">
                        {fam.age && <p>{fam.age}</p>}
                      </dd>
                    </div>
                  </dl>
                </>
              ))}
          </div>
        )}
      </article>
    </React.Fragment>
  );
}
export default React.memo(UserData);
