// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.identity-card {
    border: 1px solid #ccc;
    padding: 20px;
    border-radius: 5px;
    width: 300px;
    margin: auto;
    text-align: center;
    background: rgb(202,174,238);
    background: linear-gradient(90deg, rgba(202,174,238,1) 0%, rgba(148,187,233,1) 88%);

  }
  
  .user-photo {
    margin-bottom: 20px;
  }
  
  .user-photo img {
    max-width: 100%;
    border-radius: 50%;
    border: 2px solid #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .user-info {
    margin-bottom: 20px;
  }
  
  .name {
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 5px;
    text-transform: uppercase;
  }
  
  .dob {
    font-size: 0.9rem;
  }
  
  .barcode {
    margin-top: 20px;
  }
  `, "",{"version":3,"sources":["webpack://./src/styles/People.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,aAAa;IACb,kBAAkB;IAClB,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,4BAA4B;IAC5B,mFAAmF;;EAErF;;EAEA;IACE,mBAAmB;EACrB;;EAEA;IACE,eAAe;IACf,kBAAkB;IAClB,sBAAsB;IACtB,uCAAuC;EACzC;;EAEA;IACE,mBAAmB;EACrB;;EAEA;IACE,iBAAiB;IACjB,iBAAiB;IACjB,kBAAkB;IAClB,yBAAyB;EAC3B;;EAEA;IACE,iBAAiB;EACnB;;EAEA;IACE,gBAAgB;EAClB","sourcesContent":[".identity-card {\n    border: 1px solid #ccc;\n    padding: 20px;\n    border-radius: 5px;\n    width: 300px;\n    margin: auto;\n    text-align: center;\n    background: rgb(202,174,238);\n    background: linear-gradient(90deg, rgba(202,174,238,1) 0%, rgba(148,187,233,1) 88%);\n\n  }\n  \n  .user-photo {\n    margin-bottom: 20px;\n  }\n  \n  .user-photo img {\n    max-width: 100%;\n    border-radius: 50%;\n    border: 2px solid #fff;\n    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);\n  }\n  \n  .user-info {\n    margin-bottom: 20px;\n  }\n  \n  .name {\n    font-size: 1.2rem;\n    font-weight: bold;\n    margin-bottom: 5px;\n    text-transform: uppercase;\n  }\n  \n  .dob {\n    font-size: 0.9rem;\n  }\n  \n  .barcode {\n    margin-top: 20px;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
