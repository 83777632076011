// import React from 'react';

// import { CameraIcon } from "@heroicons/react/20/solid";

// const DocumentUploader = ({ files, setFiles, openDocumentCamera }) => {
//   const handleDragOver = (e) => {
//     e.preventDefault();
//     e.stopPropagation();
//   };

//   const handleDrop = (e) => {
//     e.preventDefault();
//     e.stopPropagation();
//     const droppedFiles = Array.from(e.dataTransfer.files);
//     setFiles(prevFiles => [...(prevFiles || []), ...droppedFiles]);
//   };

//   const handleFileSelect = (e) => {
//     const selectedFiles = Array.from(e.target.files);
//     setFiles(prevFiles => [...(prevFiles || []), ...selectedFiles]);
//   };

//   return (
//     <div className="col-span-full">
//       <div 
//         className="relative mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10"
//         onDragOver={handleDragOver}
//         onDrop={handleDrop}
//       >
//         <div className="text-center">
//           {/* <Camera className="mx-auto h-12 w-12 text-gray-300" aria-hidden="true" /> */}
//           <CameraIcon
            
//             className="h-7 w-7"
//             style={{ margin: "auto" }}
//           />
//           <div className="mt-4 flex text-sm leading-6 text-gray-600">
//             <label
//               htmlFor="document-upload"
//               className="relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500"
//             >
//               <span>Upload files</span>
//               <input 
//                 id="document-upload" 
//                 name="document-upload" 
//                 type="file" 
//                 className="sr-only" 
//                 multiple
//                 onChange={handleFileSelect}
//               />
//             </label>
//             <p className="pl-1">or drag and drop</p>
//           </div>
//           <button
//             type="button"
//             onClick={openDocumentCamera}
//             className="mt-2 text-sm font-semibold text-indigo-600 hover:text-indigo-500"
//           >
//             Capture with camera
//           </button>
//         </div>
//       </div>


//       {/* Preview area */}
//       {files && files.length > 0 && (
//         <div className="mt-4 grid grid-cols-2 gap-4 sm:grid-cols-3 md:grid-cols-4">
//           {files.map((file, index) => (
//             <div key={index} className="relative group">
//               <img
//                 src={URL.createObjectURL(file)}
//                 alt={`Document ${index + 1}`}
//                 className="h-24 w-full object-cover rounded-lg"
//               />
//               <button
//                 onClick={() => setFiles(files.filter((_, i) => i !== index))}
//                 className="absolute top-1 right-1 bg-red-500 text-white rounded-full p-1 opacity-0 group-hover:opacity-100 transition-opacity"
//               >
//                 <svg
//                   xmlns="http://www.w3.org/2000/svg"
//                   className="h-4 w-4"
//                   fill="none"
//                   viewBox="0 0 24 24"
//                   stroke="currentColor"
//                 >
//                   <path
//                     strokeLinecap="round"
//                     strokeLinejoin="round"
//                     strokeWidth={2}
//                     d="M6 18L18 6M6 6l12 12"
//                   />
//                 </svg>
//               </button>
//             </div>
//           ))}
//         </div>
//       )}
//     </div>
//   );
// };

// export default DocumentUploader;


import React from 'react';

import { CameraIcon } from "@heroicons/react/20/solid";
import { useTranslation } from "react-i18next";
const DocumentUploader = ({ files, setFiles, openDocumentCamera }) => {
  const { t, i18n } = useTranslation();
  return (
    <div className="col-span-full">
      <div 
        className="relative mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10"
      >
        <div className="text-center">
          {/* <Camera className="mx-auto h-12 w-12 text-gray-300" aria-hidden="true" /> */}
          <CameraIcon
            
            className="h-7 w-7"
            style={{ margin: "auto" }}
          />
          <button
            type="button"
            onClick={openDocumentCamera}
            className="mt-2 text-sm font-semibold text-indigo-600 hover:text-indigo-500"
          >
           
            {t("Capture with camera")}
          </button>
        </div>
      </div>


    </div>
  );
};

export default DocumentUploader;